/* eslint-disable */
import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useOpenContactModal } from '../../hooks';
import newStyles from '../../pages/styles/newPolicy.module.scss';
import sitemapStyles from '../../pages/styles/sitemap.module.scss';
import { MODAL_SOURCE_BUTTONS } from '../../constants/modal';

const { SITEMAP } = MODAL_SOURCE_BUTTONS;

export default function DesktopSitemap() {
    const { t } = useTranslation();

    const openContactModal = useOpenContactModal(SITEMAP);

    return (
        <>
            <div id={newStyles.mainImageWrapper}>
                <div className={newStyles.mainImageTextWrapper}>
                    <div className={newStyles.mainImageLeft}>
                        <div className={newStyles.mainImageContent}>
                            <h1 className={newStyles.mainImageTitle}>
                                {t('siteMetadata.sitemap.title')}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id={sitemapStyles.whiteSection}>
                <h3 id={sitemapStyles.whiteSectionTitle}>
                    {t('siteMetadata.sitemap.products')}
                </h3>
                <div className={sitemapStyles.columns}>
                    <ul>
                        <li>
                            <h2 id={sitemapStyles.whiteSectionSubtitle}>
                                <div className={sitemapStyles.subtitle}>
                                    {t('siteMetadata.sitemap.witbeSuite')}
                                </div>
                            </h2>
                        </li>
                        <li>
                            <Link to='/technology#suite' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.REC')}
                            </Link>
                        </li>
                        <li>
                            <Link to='/technology#suite' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.smartgate')}
                            </Link>
                        </li>
                        <li>
                            <Link to='/technology#suite' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.workbench')}
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h2 id={sitemapStyles.whiteSectionSubtitle}>
                                {t('siteMetadata.sitemap.witbox')}
                            </h2>
                        </li>
                        <li>
                            <Link to='/products/witbox/' className={sitemapStyles.sitemapLink} dangerouslySetInnerHTML={{__html: t(`siteMetadata.sitemap.witboxOne`)}} />
                        </li>
                        <li>
                            <Link to='/products/witbox/' className={sitemapStyles.sitemapLink} dangerouslySetInnerHTML={{__html: t(`siteMetadata.sitemap.witboxPlus`)}} />
                        </li>
                        <li>
                            <Link to='/products/witbox/' className={sitemapStyles.sitemapLink} dangerouslySetInnerHTML={{__html: t(`siteMetadata.sitemap.witboxNet`)}} />
                        </li>
                    </ul>
                </div>
                <h3 id={sitemapStyles.whiteSectionTitle}>
                    {t('siteMetadata.sitemap.technology')}
                </h3>
                <div className={sitemapStyles.columns}>
                    <ul>
                        <li>
                            <h2 id={sitemapStyles.whiteSectionSubtitle}>
                                <div className={sitemapStyles.subtitle}>
                                    {t('siteMetadata.sitemap.useCases')}
                                </div>
                            </h2>
                        </li>
                        <li>
                            <Link to='/technology/qa-test-automation/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.qa')}
                            </Link>
                        </li>
                        <li>
                            <Link to='/technology/remote-device-access/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.rda')}
                            </Link>
                        </li>
                        <li>
                            <Link to='/technology/video-service-monitoring/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.vsm')}
                            </Link>
                        </li>
                        <li>
                            <Link to='/technology/vod-asset-checking/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.vac')}
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h2 id={sitemapStyles.whiteSectionSubtitle}>
                                <div className={sitemapStyles.subtitle}>
                                    {t('siteMetadata.sitemap.documentation')}
                                </div>
                            </h2>
                        </li>
                        <li>
                            <Link to='/technology/supported-devices/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.devices')}
                            </Link>
                        </li>
                    </ul>
                </div>
                <h3 id={sitemapStyles.whiteSectionTitle}>
                    {t('siteMetadata.sitemap.about')}
                </h3>
                <div className={sitemapStyles.columns}>
                    <ul>
                        <li>
                            <h2 id={sitemapStyles.whiteSectionSubtitle}>
                                <div className={sitemapStyles.subtitle}>
                                    {t('siteMetadata.sitemap.company')}
                                </div>
                            </h2>
                        </li>
                        <li>
                            <Link to='/about/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.aboutWitbe')}
                            </Link>
                        </li>
                        {/* <li>
                            <Link to='/about/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.leadership')}
                            </Link>
                        </li> */}
                        <li>
                            <Link to='/careers/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.careers')}
                            </Link>
                        </li>
                        <li>
                            <a className={sitemapStyles.sitemapLink} target='_blank' href='http://alwit.net/' rel='noopener noreferrer'>
                                {t('siteMetadata.sitemap.investors')}
                            </a>
                        </li>
                        <li>
                            <div
                                className={sitemapStyles.sitemapLink}
                                onClick={openContactModal}
                            >
                                {t('siteMetadata.sitemap.contact')}
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h2 id={sitemapStyles.whiteSectionSubtitle}>
                                {t('siteMetadata.sitemap.values')}
                            </h2>
                        </li>
                        <li>
                            <Link to='/policy/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.privacy')}
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h2 id={sitemapStyles.whiteSectionSubtitle}>
                                {t('siteMetadata.sitemap.news')}
                            </h2>
                        </li>
                        <li>
                            <Link to='/articles/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.articles')}
                            </Link>
                        </li>
                        <li>
                            <Link to='/events/' className={sitemapStyles.sitemapLink}>
                                {t('siteMetadata.sitemap.events')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}
