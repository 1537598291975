/* eslint-disable */
import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/common';
import Banner from '../images/policy/Banner.svg';
import styles from './styles/policy.module.scss';
import newStyles from './styles/newPolicy.module.scss';
import sitemapStyles from './styles/sitemap.module.scss';
import SEO from '../components/common/SEO';
import { useViewport } from '../hooks';
import DesktopSitemap from '../components/common/DesktopSitemap';
import MobileSitemap from '../components/common/MobileSitemap';

export default function Sitemap() {
    const { width } = useViewport();
    const breakpoint = 500;
    const breakpointTablets = 821;

    return (
        <Layout title="Site Map - Witbe">
            <SEO
                title='Site Map - Witbe'
                ogDescription='Use this sitemap to find your path around witbe.net. Learn about Witbe’s products and what we do.'
                description='Use this sitemap to find your path around witbe.net. Learn about Witbe’s products and what we do.'
                image={Banner}
            />
            {width < breakpoint ? ( <MobileSitemap /> ) : ( <DesktopSitemap /> )}

        </Layout>
    )
}
